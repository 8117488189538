<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <div class="app-fixed-search d-flex">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              style="padding-top: 2.8%"
              placeholder="Search"
            />
          </b-input-group>
          <!-- <b-form-datepicker
            id="example-datepicker"
            v-model="value"
            class="mb-2"
            placeholder="Start Date"
            calendar-width="100%"
          />
          <b-form-datepicker
            id="example-datepicker"
            v-model="value"
            class="mb-2"
            placeholder="End Date"
            calendar-width="100%"
          /> -->
          <div class="view-options d-flex pb-1 pr-2">
            <div class="pt-2">
              <calendar-icon
                size="1.5x"
                class="custom-class"
              />
            </div>
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              opens="left"
              class="pt-1"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
            >
              <template
                v-slot:input="picker"
                style="min-width: 350px"
              >
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
            </date-range-picker>

            <div class="pr-1 pt-2 pb-0">
              <arrow-down-circle-icon
                size="1.5x"
                class="custom-class"
                @click="downloadFile"
              />
            </div>
            <div
              v-if="viewDetails"
              class="pr-1 pt-2 pb-0"
            >
              <trash-icon
                size="1.5x"
                class="custom-class"
                @click="checkFile"
              />
            </div>
            <!-- <div class="pr-1 pt-2 pb-0">
              <alert-circle-icon
                size="1.5x"
                class="custom-class"
              />
            </div>
            <div class="pr-1 pt-2 pb-0">
              <more-vertical-icon
                size="1.5x"
                class="custom-class"
              />
            </div> -->
            <!-- <div class="view-options mt-0"> -->
            <b-form-radio-group
              class="ml-1 pt-1 list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon
                  :icon="option.icon"
                  size="18"
                  @click="viewType(option.value)"
                />
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <div class="">
          <transition>
            <div v-if="view.folder">
              <div class="mt-2">
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="ArrowLeftIcon"
                  size="25"
                  @click="triggerEvent"
                />
              </div>
              <Folder :all-files="folderItems" />
            </div>
          </transition>
          <transition>
            <div v-if="view.folder === false">
              <div class="m-1">
                <span
                  style="font-size: 15px"
                  class="font-weight-bolder"
                >Folders</span>
              </div>
              <!-- <div class="viewType"> -->
              <div
                v-if="gridView"
                class="row"
                style="margin: 0; padding: 0"
              >
                <div

                  v-for="(product, index) in allFiles"
                  :key="index"
                  class="p-1"
                  style="position: relative"
                  @mouseover="fileHover(index)"
                  @mouseleave="hover1 = false"
                >
                  <b-card

                    class="ecommerce-card m-1 cursor-pointer"
                    style="width: 280p; position: relative"
                    :style="
                      product.deleteStatus
                        ? ' border: 1px solid rgb(33 173 95)'
                        : ''
                    "
                    no-body
                  >
                    <div
                      style="position: absolute"
                      class="p-1"
                    >
                      <b-form-checkbox
                        v-model="product.deleteStatus"
                      />
                    </div>

                    <div>
                      <div class="item-img text-center">
                        <div
                          style="
                            background-color: #f8f8f8;
                            height: 150px;
                            opacity: 0.8;
                          "
                          class="p-2"
                        >
                          <div
                            style="height: 110px; margin-top: 30px"
                            @click="viewFolder(product.data.items)"
                          >
                            <feather-icon
                              icon="FolderIcon"
                              size="40"
                              stroke="grey"
                            />
                          </div>
                        </div>
                      </div>

                      <b-card-body @click="viewFolder(product.data.items)">
                        <h6 class="item-name">
                          <div class="d-flex justify-content-between">
                            <div class="item-rating mr-2">
                              <div
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  width: 150px;
                                "
                              >
                                {{ product.data.title }}
                              </div>
                            </div>

                            <div>
                              <h6
                                class="item-price"
                                style="color: #b9b9c3"
                              >
                                50.34mb
                              </h6>
                            </div>
                            <!-- <div>
                          <h6
                            class="item-price"
                            style="color: #B9B9C3"
                          >
                            {{ getExtension(product.originalFileName) }}
                          </h6>
                        </div> -->
                          </div>
                          <div>
                            <h6
                              class="item-price mt-0.5"
                              style="color: #b9b9c3"
                            >
                              Last accessed 21 hour ago
                            </h6>
                          </div>
                        </h6>
                        <b-card-text
                          class="item-description"
                          style="color: #b9b9c3"
                        >
                          {{ product.data.date }}
                        </b-card-text>
                      </b-card-body>
                    </div>

                  </b-card>

                </div>

              </div>
              <div
                v-if="listView"
                class="p-2"
              >
                <div>
                  <div
                    class="row justify-content-between pr-4 pb-1"
                    style="width: 280p; position: relative"
                  >
                    <div class="pl-4 justify-content-start">
                      <div class="row">
                        <div
                          style="width:50px;"
                          class="pr-5"
                        />
                        <div class="pl-4">
                          <h6
                            class="item-price pt-1"
                            style="color: #5E5873"
                          >
                            FILE NAME
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-end">
                      <div class="pr-4">
                        <h6
                          class="item-price pt-1"
                          style="color: #5E5873"
                        >
                          SIZE
                        </h6>
                      </div>
                      <div class="pl-3 pr-5">
                        <h6
                          class="item-price pt-1"
                          style="color: #5E5873"
                        >
                          LAST MODIFIED
                        </h6>
                      </div>
                      <div class="pl-2 pr-2">
                        <h6
                          class="item-price pt-1"
                          style="color: #5E5873"
                        >
                          ACTIONS
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="filesStyle p-1 ml-1 mr-1 cursor-pointer"
                  style="width: 280p; position: relative"
                >
                  <div class="row justify-content-start">
                    <div class="mt-1 pl-5">
                      <feather-icon
                        icon="ArrowUpIcon"
                        size="20"
                        class="text-body align-middle mr-25 purpleColor"
                      /></div>

                    <div>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreHorizontalIcon"
                            size="20"
                            class="text-body align-middle mr-25 purpleColor"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteRecord(data.item.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(product, index) in allFiles"
                  :key="index"
                  class="p-1"
                  style="position: relative"
                  @mouseover="fileHover(index)"
                  @mouseleave="hover1 = false"
                >
                  <div
                    class="filesStyle p-1 cursor-pointer"
                    style="width: 280p; position: relative"
                    :style="
                      product.deleteStatus
                        ? ' border: 1px solid rgb(33 173 95)'
                        : ''
                    "
                    no-body
                  >
                    <div>
                      <div class="row justify-content-between">
                        <div
                          class="row"
                          style="width:30%;"
                        >
                          <div class="pl-3">
                            <div
                              style="position: absolute"
                              class="pt-1"
                            >
                              <b-form-checkbox
                                v-model="product.deleteStatus"
                              />
                            </div>
                          </div>
                          <div class="pl-3">
                            <div
                              style="
                            opacity: 0.8;
                          "
                            >
                              <div
                                class="pt-1"
                              >
                                <feather-icon
                                  icon="FolderIcon"
                                  size="20"
                                  stroke="grey"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="pl-2">
                            <div
                              class="item-rating mr-2 pt-1"
                              @click="viewFolder(product.data.items)"
                            >
                              <div
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  width: 150px;
                                "
                              >
                                {{ product.data.title }}
                              </div>
                            </div>

                          </div>
                        </div>
                        <div
                          style="width:70%;"
                          class="row justify-content-end"
                        >
                          <div class="pl-1">
                            <div>
                              <h6
                                class="item-price pt-1"
                                style="color: #b9b9c3"
                              >
                                50.34mb
                              </h6>
                            </div>
                          </div>
                          <div class="pl-2">
                            <h6
                              class="item-price pt-1"
                              style="color: #b9b9c3"
                            >
                              Last accessed 21 hour ago
                            </h6>
                          </div>
                          <div class="pl-4 pr-3">
                            <div>
                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                              >
                                <template v-slot:button-content>
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25 purpleColor"
                                  />
                                </template>
                                <b-dropdown-item>
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="mr-50"
                                  />
                                  <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteRecord(data.item.id)">
                                  <feather-icon
                                    icon="TrashIcon"
                                    class="mr-50"
                                  />
                                  <span>Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
              </div>
            </div></transition>
          <div>
            <b-modal
              id="modal-delete-confirm"
              v-model="showOptionsModal"
              centered
              hide-footer
            >
              <p class="my-1 font-weight-bold">
                Confirm to delete this file?
              </p>
              <div class="float-right">
                <b-button
                  variant="danger"
                  class="btn-wishlist mr-1"
                  @click="apiDeleteFolder"
                >
                  <span>Delete</span>
                </b-button>
                <b-button
                  variant="light"
                  class="btn-wishlist"
                  @click="$bvModal.hide('modal-delete-confirm')"
                >
                  <span>Cancel</span>
                </b-button>
              </div>
            </b-modal>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <portal to="content-renderer-sidebar-left">
      <doc-left-sidebar @refresh="apiGetAllFiles" />
    </portal>
  </div>
</template>

<style>
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #dc3545 !important;
  color: white;
}
reportrange-text {
  border: 0px !important;
}

.filesStyle {
  margin-bottom: 0rem !important;
  border: 2px solid #EBE9F1;
  border-radius: 8px;
  box-shadow: none !important;
}

/* .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2], .daterangepicker.show-ranges[data-v-1ebd09d2] {
    min-width: 400px;
} */

/* [dir] .daterangepicker .drp-calendar.right{
  padding-top: 30%;
} */
</style>

<script>
import { ref } from "@vue/composition-api"
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormRadioGroup,
  BFormRadio,
  BCard,
  BCardBody,
  BCardText,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue"
import {
  CalendarIcon,
  ArrowDownCircleIcon,
  TrashIcon,
} from "vue-feather-icons"
import DateRangePicker from "vue2-daterange-picker"
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
// import axios from 'axios'
import DocLeftSidebar from "./component/DocLeftSidebar.vue"
import Folder from "./Folder.vue"
// import useMediaUi from '../media_gallery/useMediaGalleryDeep'

export default {
  components: {
    BFormInput,
    BCard,
    BCardBody,
    BCardText,
    DateRangePicker,
    // AlertCircleIcon,
    // MoreVerticalIcon,
    BFormRadioGroup,
    BFormRadio,
    CalendarIcon,
    BInputGroup,
    BInputGroupPrepend,
    VuePerfectScrollbar,
    ArrowDownCircleIcon,
    TrashIcon,
    BDropdown,
    BDropdownItem,
    BButton,
    // App SFC
    DocLeftSidebar,
    Folder,
    BFormCheckbox,
  },
  data() {
    return {
      gridView: true,
      listView: false,
      checkIdx: false,
      totals: 0,
      viewDetails: true,
      hover1: false,
      dateRange: {},
      folderItems: [],
      fileDel: [],
      tempArr: [],
      view: {
        folder: false,
      },
      itemView: "grid-view",
      itemViewOptions: [
        { icon: "GridIcon", value: "grid-view" },
        { icon: "ListIcon", value: "list-view" },
      ],
      files: {},
      tempObj: {},
      allFiles: [],
      currFile: [],
      currIdx: null,
      images: [
        {
          name: "image1",
          url: "https://images.unsplash.com/photo-1515165562839-978bbcf18277?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
        },
        {
          name: "image2",
          url: "https://images.unsplash.com/photo-1527295110-5145f6b148d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1431&q=80",
        },
        {
          url: "https://images.unsplash.com/photo-1542731538-f99c9f100885?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80",
        },
      ],
      showOptionsModal: false,
      selectedFolder: null,
    }
  },
  created() {
    this.$http.get("/documents/data").then(response => {
      console.log(response)
      const files = response.data
      this.files = files
    })
    this.$imageViewer.images(this.images)
  },
  mounted() {
    this.apiGetAllFiles()
  },
  methods: {
    rowClass() {
      // if (!item || type !== 'row') return
      // if (item.status === 'awesome') return 'table-success'
      return 'bordered rounded'
    },
    viewType(val){
      console.log(val)
      if (val === 'list-view') {
        this.gridView = false
        this.listView = true
      } else {
        this.listView = false
        this.gridView = true
      }
    },
    triggerEvent() {
      this.view.folder = false
      this.viewDetails = true
    },
    // eslint-disable-next-line no-unused-vars
    showSelected(id) {
      console.log(id)
      this.id = this.isShowSelected
      // console.log('test error: ' + this.isShowSelected)
      this.isShowSelected = true
    },
    fileHover(idx) {
      this.currIdx = idx
    },
    deleteFile() {
      this.$bvModal.show("modal-delete-confirm")
      this.currFile = []
    },
    async downloadFile() {
      this.totals = 0
      this.tempArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.allFiles.length; i++){
        if (this.allFiles[i].deleteStatus === true) {
          // console.log(this.allFiles[i].data.items)
          this.totals += 1
          this.tempArr = this.allFiles[i].data.items
        }
      }
      console.log(this.totals)
      if (this.totals === 1){
        const urls = []
        // const zip = new JSZip()
        this.tempArr.forEach(items => {
          urls.push(items.s3imageUrl)
          // response = this.$axios.get(items.s3imageUrl, {
          //   responseType: 'blob',
          // })
        })
        // const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        // link.href = url
        link.setAttribute("download", "file.zip")
        document.body.appendChild(link)
        link.click()
      } else if (this.totals === 0) {
        this.$toast.error("Please select at least one folder!", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        })
      } else {
        this.$toast.error("You can only download one folder at a time!", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        })
      }
    },
    checkFile() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.allFiles.length; i++){
        if (this.allFiles[i].deleteStatus === true) {
          this.checkIdx = true
          break
        }
      }
      if (this.checkIdx){
        this.$bvModal.show("modal-delete-confirm")
      } else {
        this.$toast.error("Please select at least one folder!", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        })
      }
    },
    apiDeleteFolder() {
      const toDelObject = []
      this.allFiles.forEach(folder => {
        if (folder.deleteStatus === true) {
          toDelObject.push(folder)
        }
      })

      let indexCount = 0
      toDelObject.forEach(folder => {
        console.log(folder)
        this.$axios
          .delete(`${this.$baseUrl}/files/${folder.data.id}`)
          .then(response => {
            // eslint-disable-next-line no-plusplus
            indexCount += 1
            if (response.status === 200 || response.status === 201) {
              const { data } = response
              console.log(data)
              const timeout = 4000
              this.$toast(
                {
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Info`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Folder ${folder.data.title} deleted!`,
                  },
                },
                { timeout },
              )
              this.$bvModal.hide("modal-delete-confirm")
              if (toDelObject.length === indexCount) this.apiGetAllFiles()
            }
          })
      })
    },
    deleteFolder() {
      console.log("confirm delete")
      console.log(this.selectedFolder)
      this.apiDeleteFolder()
    },
    deleteOptions(id) {
      console.log("delete")
      console.log(id)
      this.selectedFolder = id
      this.showOptionsModal = true
    },
    viewFolder(items) {
      this.viewDetails = false
      this.folderItems = items
      this.view.folder = true
    },

    getExtension(file) {
      const extension = file.split(".")
      return extension[extension.length - 1].toLowerCase()
    },
    imageView(path) {
      const image = [
        {
          name: "image1",
          url: path,
        },
      ]
      this.$imageViewer.images(image)
      this.$imageViewer.show()
    },
    apiGetAllFiles() {
      this.allFiles = []
      // var tempObj = {}
      this.$axios.get(`${this.$baseUrl}/files`).then(response => {
        if (response.status === 200 || response.status === 201) {
          response.data.forEach(folder => {
            this.allFiles.push({
              deleteStatus: false,
              data: folder,
            })
          })
          console.log(this.allFiles)
        }
      })
    },
  },
  setup() {
    const tasks = ref([])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // const {
    //   itemView, itemViewOptions,
    // } = useMediaUi()

    // eslint-disable-next-line no-use-before-define

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      tasks,
      perfectScrollbarSettings,

      // itemView,
      // itemViewOptions,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
  // changeColour(){
  //   if (isColoured){
  //     $(".card-body").css("background-color", "transparent")
  //     isColoured = false
  //   } else {
  //     $(".card-body").css("background-color", "red")
  //     isColoured = true
  //   }
  // },
}
</script>

<style scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
