<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              variant="primary"
              block
              @click="$emit('close-left-sidebar')"
            >
              Upload Document
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Label
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tag.icon"
                  size="18"
                  class="mr-75"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-center"
      hide-footer
      size="lg"
      centered
      @show="resetModal"
    >
      <div>
        <b-form>
          <div class="row">
            <div class="col-sm-6">
              <b-form-group
                label="Folder Name"
                label-for="File-title"
              >
                <b-form-input
                  id="file-title"
                  v-model="fileTitle"
                />
              </b-form-group>
            </div>
            <div
              class="d-flex flex-column col-sm-6"
            >
              <b-form-group
                label="Date"
                label-for="date"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="date"
                    v-model="selectedDate"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="selectedDate"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="en-US"
                      aria-controls="date"

                      @context="onContext"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <div v-if="selectedDate !== '' && fileTitle !== null">
          <vue-dropzone
            id="dropzone"
            ref="vue2Dropzone"
            class="dropzoneClass"
            :options="dropzoneOptions"
            @vdropzone-files-added="uploadFile"
          ><div
            class="dz-default dz-message"
            data-dz-message
          >
            <span>Your Custom Message</span>
          </div></vue-dropzone>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BModal, BListGroup, BListGroupItem, BForm, BFormGroup, BFormInput, BFormDatepicker, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import moment from 'moment'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'dropzone-vue/dist/dropzone-vue.common.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BFormGroup,
    BModal,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vueDropzone: vue2Dropzone,
  },
  data(){
    return {
      notEmpty: false,
      fileObj: {},
      isUploadFile: true,
      isFileGroup: false,
      selectedDate: '',
      fileTitle: '',
      date: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: ".xls, .xlsx, .ppt, .jpg, .jpeg, .png, .docx, .pdf",
        maxFiles: 10,
        // clickable: false,
        dictDefaultMessage: 'Drop your files here<p>or</p><div class="btn btn-primary" style="width: 150px" @click="uploadFile">Browse</div>',
        // thumbnailWidth: 150,
        // maxFilesize: 0.5,
        // autoProcessQueue: false,
      },
      hardcodedProjectID: '61b17b9a5b46fc0725091390',
    }
  },
  // props: {
  //   taskTags: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  methods: {
    resetModal(){
      this.fileTitle = null
      this.date = null
    },
    onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.selectedDate = ctx.selectedYMD

      this.date = moment(this.selectedDate, 'YYYY-MM-DD').toDate().valueOf()
      console.log(this.date)
    },
    uploadFile(value) {
      console.log('test')
      console.log(value)
      console.log('test')
      const files = [...value]
      const formData = new FormData()
      files.forEach(file => {
        formData.append('file', file)
      })
      formData.append('projectId', this.hardcodedProjectID)
      formData.append('bucket', 'gallery')

      console.log(files)
      this.$axios.post(`${this.$baseUrl}/S3upload/create`, formData).then(response => {
        if (response.status === 200 || response.status === 201) {
          console.log('response data')
          console.log(response)
          console.log('response data')
          // this.$toast.success("File Successully Uploaded!")
          // this.isUploadFile = false
          // this.isFileGroup = true
          // this.fileObj = response.data
          this.createFileGroup(response.data)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Failed to add file(s)!`,
            },
          })
        }
      })
    },
    createFileGroup(data) {
      const FilesGroup = {
        title: this.fileTitle,
        project: "61b17b9a5b46fc0725091390",
        timestamp: this.date,
        items: data,
      }
      this.$axios.post(`${this.$baseUrl}/files`, FilesGroup).then(response2 => {
        if (response2.status === 200 || response2.status === 201) {
          this.$emit('refresh')
          this.$toast.success("File Successully Added!")
          this.$bvModal.hide('modal-center')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Error!`,
            },
          })
        }
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'My Drive', icon: 'FolderIcon', route: { name: 'file-view' } },
      { title: 'Important', icon: 'StarIcon', route: { name: 'file-view-filter', params: { filter: 'important' } } },
      { title: 'Recents', icon: 'ClockIcon', route: { name: 'file-view-filter', params: { filter: 'completed' } } },
      { title: 'Deleted', icon: 'TrashIcon', route: { name: 'file-view-filter', params: { filter: 'deleted' } } },
    ]

    const taskTags = [
      { title: 'Documents', icon: 'FileTextIcon', route: { name: 'file-view-tag', params: { tag: 'team' } } },
      { title: 'CSV', icon: 'ImageIcon', route: { name: 'file-view-tag', params: { tag: 'low' } } },
      { title: 'Excel', icon: 'FileTextIcon', route: { name: 'file-view-tag', params: { tag: 'medium' } } },
      { title: 'Images', icon: 'FileTextIcon', route: { name: 'file-view-tag', params: { tag: 'high' } } },
      { title: 'Achieve Files', icon: 'LayersIcon', route: { name: 'file-view-tag', params: { tag: 'update' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      taskTags,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
