<template>
  <div style="">
    <div
      class=""
    />
    <div class="">
      <div class="">
        <div class="row">
          <div
            v-for="(product, index) in allFiles"
            :key="index"
            class="p-2"
          >
            <span style="color: #5E5873">{{ product.title }}</span>
            <b-card
              class="ecommerce-card m-1"
              style="width: 250px"
              no-body
            >

              <div
                v-if="getExtension(product.originalFileName) === 'png' || getExtension(product.originalFileName) === 'jpg' || getExtension(product.originalFileName) === 'jpeg'"
                class="image-view"
                @click="imageView(product.s3imageUrl)"
              >
                <!-- @click="imageView(product.s3imageUrl)" -->
                <div class="item-img text-center">
                  <div
                    style="background-color: #F8F8F8; height: 150px; opacity: 0.8"
                    class="p-1"
                  >
                    <!-- <div class="custom-control custom-checkbox image-checkbox">
                      <input
                        id="ck1a"
                        type="checkbox"
                        class="custom-control-input"
                        @click="delFiles(product.key)"
                      >
                      <label
                        class="custom-control-label"
                        for="ck1a"
                      > -->
                    <img
                      class="card-img-top"
                      style="height: inherit"
                      :src="product.s3imageUrl"
                    >
                    <!-- </label>
                    </div> -->
                  </div>
                </div>
                <b-card-body>
                  <h6 class="item-name">
                    <div class="d-flex justify-content-between">
                      <div class="item-rating mr-2">
                        <div
                          style=" white-space: nowrap; overflow: hidden;
                            text-overflow: ellipsis; width: 150px; "
                        >{{ product.originalFileName }}</div>
                      </div>
                      <div>
                        <h6
                          class="item-price"
                          style="color: #B9B9C3"
                        >
                          {{ getExtension(product.originalFileName) }}
                        </h6>
                      </div>
                    </div>
                  </h6>
                  <b-card-text
                    class="item-description"
                    style="color: #B9B9C3"
                  >
                    {{ convertedDate(product.date) }}
                  </b-card-text>
                </b-card-body>
              </div>
              <div
                v-else-if="getExtension(product.originalFileName) === 'mp4' || getExtension(product.originalFileName) === 'mov' || getExtension(product.originalFileName) === 'wmv'"
                class="image-view"
                @click="videoView(product.s3imageUrl)"
              >
                <div class="item-img text-center">
                  <div
                    style="background-color: #F8F8F8; height: 150px; opacity: 0.8"
                    class="p-1"
                  >
                    <div style="height: 110px;">
                      <img
                        class="card-img-top"
                        style="height: 110px; width: 120px"
                        :src="`${publicPath}video_icon.png`"
                      >
                    </div>
                  </div>
                </div>
                <b-card-body>
                  <h6 class="item-name">
                    <div class="d-flex justify-content-between">
                      <div class="item-rating mr-2">
                        <div
                          style=" white-space: nowrap; overflow: hidden;
                            text-overflow: ellipsis; width: 150px; "
                        >{{ product.originalFileName }}</div>
                      </div>
                      <div>
                        <h6
                          class="item-price"
                          style="color: #B9B9C3"
                        >
                          {{ getExtension(product.originalFileName) }}
                        </h6>
                      </div>
                    </div>
                  </h6>
                  <b-card-text
                    class="item-description"
                    style="color: #B9B9C3"
                  >
                    {{ product.date }}
                  </b-card-text>
                </b-card-body>
              </div>

              <router-link
                v-else
                :to="{name: 'view-files', params: {type: getExtension(product.originalFileName), path: product.s3imageUrl, name: product.originalFileName}}"
              >
                <div class="item-img text-center">
                  <div
                    style="background-color: #F8F8F8; height: 150px;"
                    class="p-1  d-flex  align-items-center justify-content-center"
                  >
                    <!-- <div class="custom-control custom-checkbox image-checkbox">
                      <input
                        id="ck1a"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="ck1a"
                      > -->
                    <img
                      v-if="getExtension(product.originalFileName)==='docx'"
                      class="text-center"
                      style="height: 70px; width: 70px"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/2048px-.docx_icon.svg.png"
                    >
                    <img
                      v-else-if="getExtension(product.originalFileName) ==='ppt'"
                      class="text-center"
                      style="height: 70px; width: 70px"
                      src="https://mpng.subpng.com/20180504/alq/kisspng-microsoft-powerpoint-presentation-microsoft-office-5aebe11fe148c3.0808050015254080319228.jpg"
                    >
                    <img
                      v-else
                      class="text-center"
                      style="height: 70px; width: 70px"
                      :src="getExtension(product.originalFileName)==='csv' || getExtension(product.originalFileName)==='xls' || getExtension(product.originalFileName)==='xlsx'? 'https://cdn-icons-png.flaticon.com/512/888/888850.png' : 'https://www.iconpacks.net/icons/2/free-pdf-icon-1512-thumb.png'"
                    >
                    <!-- </label>
                    </div> -->
                  </div>
                </div>

                <!-- Product Details -->
                <b-card-body>
                  <h6 class="item-name">
                    <div class="d-flex justify-content-between">
                      <div class="item-rating mr-2">
                        <span>{{ product.originalFileName }}</span>
                      </div>
                      <div>
                        <h6
                          class="item-price"
                          style="color: #B9B9C3"
                        >
                          {{ getExtension(product.originalFileName) }}
                        </h6>
                      </div>
                    </div>
                  </h6>
                  <b-card-text
                    class="item-description"
                    style="color: #B9B9C3"
                  >
                    {{ product.date }}
                  </b-card-text>
                </b-card-body>
              </router-link>

            </b-card>

          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-video"
      hide-footer
      centered
      hide-header
      size="lg"
      body-class="noBackground"
    >
      <div
        class="d-flex justify-content-center"
        style="position: relative"
      >

        <vue-plyr
          :options="options"
        >
          <video
            autoplay
            controls
            crossorigin
            playsinline
          >
            <source
              size="1080"
              :src="`${srcVid}`"
              type="video/mp4"
            >
            <track
              default
              kind="captions"
              label="English captions"
              src="/path/to/english.vtt"
              srclang="en"
            >
          </video>
        </vue-plyr>
        <div
          class="bg-white divCloseBtn"
          style="position: absolute; right: -30px; top: -18px;"
          @click="imgCloseVid"
        >
          <feather-icon
            icon="XIcon"
            size="44"
            class="align-middle text-body closeBtn"
          />
        </div>
      </div>
      <!-- <div :style="{'background-image': 'url(' + require(`${publicPath}${srcImg}`) + ')'}" /> -->
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText,
} from 'bootstrap-vue'
import moment from "moment"

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
  props: {
    allFiles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      srcVid: '',
      options: { quality: { default: '1080p' } },
      files: {},
      imgDel: [],
      images: [
        {
          name: "image1",
          url: "https://images.unsplash.com/photo-1515165562839-978bbcf18277?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
        },
        {
          name: "image2",
          url: "https://images.unsplash.com/photo-1527295110-5145f6b148d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1431&q=80",
        },
        {
          url: "https://images.unsplash.com/photo-1542731538-f99c9f100885?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80",
        },
      ],
    }
  },
  created() {
  },
  mounted(){
  },
  methods: {
    // delFiles(value) {
    //   console.log(value)
    //   this.imgDel.push(value)
    //   this.$emit('filesDel', this.imgDel)
    // },
    convertedDate(date){
      return moment(date, '').format('DD-MM-YYYY h:mm:ss a')
    },
    videoView(src){
      this.srcVid = src
      this.$bvModal.show('modal-video')
    },
    imgCloseVid(){
      this.$bvModal.hide('modal-video')
    },
    getExtension(file){
      const extension = file.split('.')
      return extension[extension.length - 1].toLowerCase()
    },
    imageView(path) {
      const image = [{
        name: "image1",
        url: path,
      }]
      this.$imageViewer.images(image)
      this.$imageViewer.show()
    },
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
